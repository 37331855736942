import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazy-load';

const CircleImage = styled.img`
    border-radius: 50%;
`;

const SitterUserAvatar = (props) => {
    const { src, alt, className, width, height } = props;
    const source =
        typeof src === 'undefined' || src === null || src === ''
            ? 'https://static1.pawshakecdn.com/icons/icon-placeholder-user.svg'
            : src;

    return (
        <LazyLoad threshold={200} height={height} width={width} className={className}>
            <CircleImage src={source} alt={alt} title={alt} height={height} width={width} />
        </LazyLoad>
    );
};

SitterUserAvatar.defaultProps = {
    width: 75,
    height: 75,
};

SitterUserAvatar.propTypes = {
    alt: PropTypes.string.isRequired,
    src: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default SitterUserAvatar;
