import React from 'react';
import styled from 'styled-components';
import { totalPages } from '../../../Pawshake/Pager/PagerUtilities';

const PagerWrapper = styled.div`
    padding: 1rem;
    display: flex;
    justify-content: center;
`;

const UserControl = styled.div`
    cursor: pointer;
    touch-action: manipulation;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
`;

const Page = styled.div`
    display: inline-block;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    &:hover {
        text-decoration: underline;
    }

    cursor: pointer;
    touch-action: manipulation;
    i {
        display: inline-block;
        width: 2rem;
    }
`;

const ActivePage = styled(Page)`
    color: white;
    background-color: #00c38a;
    border-radius: 50%;
`;

const getTotalPages = (totalPagesSupplied, total, size) => {
    if (typeof totalPagesSupplied !== 'undefined') {
        return totalPagesSupplied;
    }
    return totalPages(total, size);
};

const Pager = ({ current, totalPagesSupplied, total, size, onPageChange, trackOnPageChange }) => {
    const totalPages = getTotalPages(totalPagesSupplied, total, size);
    const iterator = [current];
    let counter = 0;

    while (iterator.length < 7 && iterator.length < totalPages) {
        if (counter % 2 === 0 && iterator[0] !== 1) {
            iterator.unshift(iterator[0] - 1);
        } else if (counter % 2 === 1 && iterator[iterator.length - 1] !== totalPages) {
            iterator.push(iterator[iterator.length - 1] + 1);
        }
        counter++;
    }

    iterator[0] = 1;
    iterator[iterator.length - 1] = totalPages;

    if (iterator.length > 1 && iterator[1] !== 2) {
        iterator[1] = 'ellipsis';
    }
    if (iterator.length > 1 && iterator[iterator.length - 2] !== totalPages - 1) {
        iterator[iterator.length - 2] = 'ellipsis';
    }

    const previous = current > 1;
    const next = current < totalPages;

    return (
        <PagerWrapper>
            {previous && (
                <UserControl
                    onClick={() => {
                        if (current > 1) {
                            trackOnPageChange();
                            onPageChange(current - 1);
                        }
                    }}
                >
                    <img alt={'previous page'} src={'https://static1.pawshakecdn.com/global/paging/previous.svg'} />
                </UserControl>
            )}
            {iterator.map((page, index) => {
                return page === 'ellipsis' ? (
                    <Page key={'ellipsis_' + index}>...</Page>
                ) : page === current ? (
                    <ActivePage key={'active_page_' + page}>{page}</ActivePage>
                ) : (
                    <Page
                        key={'page_' + page}
                        onClick={() => {
                            trackOnPageChange();
                            onPageChange(page);
                        }}
                    >
                        {page}
                    </Page>
                );
            })}
            {next && (
                <UserControl
                    onClick={() => {
                        if (current < getTotalPages(totalPagesSupplied, total, size)) {
                            trackOnPageChange();
                            onPageChange(current + 1);
                        }
                    }}
                >
                    <img alt={'next page'} src={'https://static1.pawshakecdn.com/global/paging/next.svg'} />
                </UserControl>
            )}
        </PagerWrapper>
    );
};

export default Pager;
