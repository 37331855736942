import React from 'react';
import styled from 'styled-components';

const Stars = styled.div`
    vertical-align: middle;
    display: inline-block;
    margin-right: 0.5rem;
    span {
        display: inline-block;
        width: 1rem;
    }

    img {
        width: 15px;
        height: 15px;
    }
`;

const StarRating = ({ rating, className }) => {
    let stars = [];
    for (let i = 0; i < 5; i++) {
        const svgName = i < rating ? 'star' : 'hollowStar';
        stars.push(<img alt={svgName} src={`https://static1.pawshakecdn.com/global/${svgName}.svg`} key={i} />);
    }
    return <Stars className={className}>{stars}</Stars>;
};

export default StarRating;
