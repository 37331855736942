export const totalPages = (total, size) => {
    return Math.ceil(total / size);
};

export const isRequired = (total, size) => {
    return isRequiredWithTotalPagesEqualTo(totalPages(total, size));
};

export const isRequiredWithTotalPagesEqualTo = (totalPages) => {
    return totalPages > 1;
};
