import { Locales } from '../Pawshake/Core/Domain';
import i18n from '../i18n';
import {
    EnglishAustralia,
    EnglishHongKong,
    EnglishJapan,
    EnglishNewZealand,
    EnglishSingapore,
} from '../Pawshake/Core/Locale/Locale';
import { all as countries, AU, HK, JP, NZ, SG } from '../Pawshake/Core/Locale/Country';
import { all as languages } from '../Pawshake/Core/Locale/Language';

export const scrollToTop = () => {
    window.scrollTo(0, 0);
};

export const combineWithCommasAnd = (values, all, last) => {
    let copy = values.slice();
    const lastItem = copy.splice(-1);
    copy = copy.length ? [copy.join(all)] : [];
    copy.push(lastItem);
    return copy.join(last);
};

export const getCountryOptions = () => {
    return countries.map((country) => {
        return {
            label: i18n.i18n.t(`geo:geo.countries.${country}`),
            value: country,
        };
    });
};

export const getLanguageOptions = () => {
    return languages.map((language) => {
        return {
            label: i18n.i18n.t(`geo:geo.languages.${language}`),
            value: language,
        };
    });
};

export const locales = () => {
    return Locales.map((locale) => {
        return {
            label: i18n.i18n.t(`geo:geo.countries.${locale.getCountry()}`) + ` (${locale.getLanguage().toUpperCase()})`,
            value: locale,
        };
    });
};

export const getPawshakeWorldRegionOfCountry = (country) => {
    if ([AU, NZ, HK, SG, JP].includes(country)) {
        return 'APAC';
    }
    return 'EU';
};

export const getPawshakeWorldRegionOfLocale = (locale) => {
    if (
        [
            EnglishAustralia.toString(),
            EnglishNewZealand.toString(),
            EnglishHongKong.toString(),
            EnglishSingapore.toString(),
            EnglishJapan.toString(),
        ].includes(locale)
    ) {
        return 'APAC';
    }
    return 'EU';
};
